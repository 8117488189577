import { Component } from '@angular/core';
import * as firebase from "firebase/app";
import { FirestoreOrmRepository } from "@arbel/firebase-orm";
import { environment } from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
    // initialize firebase orm
    const firebaseApp = firebase.initializeApp(environment.firebaseConfig);
    const connection = firebaseApp.firestore();
    // @ts-ignore
    FirestoreOrmRepository.initGlobalConnection(connection);
  }
}
