import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WrapperContainer } from './containers/wrapper/wrapper.container';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: WrapperContainer,
    data: { breadcrumb: 'მთავარი' },
    canActivate: [AuthGuard],
    children: [
      { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'colors', loadChildren: () => import('./pages/colors/colors.module').then(m => m.ColorsModule) },
      { path: 'categories', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule) },
      { path: 'currency', loadChildren: () => import('./pages/currency/currency.module').then(m => m.CurrencyModule) },
      { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
      { path: 'terms', loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule) },
      { path: 'privacy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule) },
      { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule) },
      { path: 'slideshow', loadChildren: () => import('./pages/slideshow/slideshow.module').then(m => m.SlideshowModule) },
      { path: 'blog', loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule) },
      { 
        path: 'products', 
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), 
        data: { breadcrumb: 'პროდუქტები' } 
      },
    ]
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
