<nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">ადმინ პანელი</a>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <input 
    *ngIf="activeRoute === '/orders' || activeRoute === '/products'"
    class="form-control form-control-dark w-100" 
    type="text" 
    placeholder="Search" 
    aria-label="ძებნა"
    (input)="onSearch($event)">
  <ul class="navbar-nav px-3">
    <li class="nav-item text-nowrap">
      <a class="nav-link" (click)="onLogout()">გამოსვლა</a>
    </li>
  </ul>
</nav>