import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderContainer } from './header.container';
import { SidebarModule } from '../sidebar/sidebar.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HeaderContainer],
  imports: [
    CommonModule,
    SidebarModule,
    RouterModule
  ],
  exports: [HeaderContainer]
})
export class HeaderModule { }
