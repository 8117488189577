import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
    private _searchSubject: Subject<string> = new Subject<string>();
    searchSubject$ = this._searchSubject.asObservable();

    changeSearchValue(value: string) {
        this._searchSubject.next(value);
    }

}
