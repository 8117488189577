import { Component, OnInit, Input } from '@angular/core';
import { INavItem } from '@interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.container.html',
  styleUrls: ['./sidebar.container.scss'],
})

export class SidebarContainer implements OnInit {
  navigation: Array<INavItem> = [
    {
      label: 'ჩვენ შესახებ',
      url: '/about',
    },
    {
      label: 'წესები',
      url: '/terms'
    }
  ];

  constructor(public router: Router) {
   
  }

  ngOnInit(): void {
  }


}
