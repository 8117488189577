<app-header></app-header>
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div class="sidebar-sticky pt-3">
                    <app-sidebar></app-sidebar>
                </div>
            </nav>
            <div class="main">
                <app-breadcrumb></app-breadcrumb>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
