import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators'
import { AuthService, SearchService } from '@services';

@Component({
  selector: 'app-header',
  templateUrl: './header.container.html',
  styleUrls: ['./header.container.scss']
})
export class HeaderContainer implements OnInit {
  activeRoute: any = '';

  constructor(private authService: AuthService,
              private searchService: SearchService,
              private router: Router) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
          this.activeRoute = event.url;
      });
  }

  ngOnInit(): void {
  }

  onSearch(e: any): void {
    this.searchService.changeSearchValue(e.target.value);
  }

  onLogout(): void {
    this.authService.SignOut();
  }

}
