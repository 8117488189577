import { Component, OnInit  } from '@angular/core';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.container.html',
  styleUrls: ['./wrapper.container.scss'],
})

export class WrapperContainer implements OnInit {
  
  constructor() {
    
   }

  ngOnInit(): void {

  }
}
